/**
 * Checks if the passed "ref" is in the viewport or not
 */
import { useState, useEffect, ReactNode, useRef, MutableRefObject } from 'react'
import 'intersection-observer' // optional polyfill

/**
 *
 * @param ref Element to check
 * @param rootMargin distance early in Px to start applying intersection
 * @returns
 */
const useOnScreen = (ref: ReactNode, rootMargin: string = '0px'): boolean => {
  const observer = useRef<IntersectionObserver | false>()
  const [isIntersecting, setIntersecting] = useState(false)

  const options = useRef({
    rootMargin
  })

  const hasIOSupport = useRef(typeof window !== 'undefined' && !!window.IntersectionObserver)

  useEffect(() => {
    observer.current = (ref as MutableRefObject<ReactNode>).current !== 'undefined' && hasIOSupport &&
      new IntersectionObserver(([entry]) => setIntersecting(entry.isIntersecting), options.current)
  }, [options, ref])

  useEffect(() => {
    observer.current && observer.current.observe((ref as MutableRefObject<Element>).current)
    // Remove the observer as soon as the component is unmounted
    return () => { observer.current && observer.current.disconnect() }
  }, [observer, ref])

  return hasIOSupport ? isIntersecting : true
}

export default useOnScreen