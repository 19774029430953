import React, { useRef, useState, FC } from 'react'
import useLazyComponent from '@hooks/useLazyComponent'
import Placeholder from '@components/placeholder'
import type { IPlaceholderType } from '@components/placeholder'

/**
 * Takes the Component passed as a param, and only loads it / shows after it is in viewport
 * 3 states in play:
 * - Module reaches scroll position
 * - Module says it's ready by itself (needs callback called from inside the module)
 * - Module removes the display: none
 */

interface ILazyComponent extends IPlaceholderType {
  children: any
  autoStart?: boolean
  id: string
  className?: string,
  cbComponentReady?: () => any
}

/**
 * LazyComponent. This Component wraps any other Component and
 * loads when it is closed in the scroll position.
 * This Component will impeed any SSR, so you can use it only for client side cases.
 * @param autoStart Sometimes you don't want the module to show, even the scroll has reached position, for example, because an asset is not ready
 * @returns JSX
 */
const LazyComponent: FC<ILazyComponent> = ({
  children,
  placeholderType = 1,
  autoStart = true,
  id,
  className,
  ...rest
}) => {
  const refEl = useRef(null)
  const [isComponentReady, setIsComponentReady] = useState(autoStart)
  const appearedFirstTime = useLazyComponent(refEl)

  // This function is called from the Component whenever it considers it's ready.
  // There's total freedom to choose what "ready" is.
  rest.cbComponentReady = () => {
    setIsComponentReady(true)
  }
  const wrapperProps = !isComponentReady ? { style: { opacity: 0, height: 0 } } : null
  return (
    <section
      id={id}
      ref={refEl}
      className={`${children.props.moduleName.replace(/\s/g, '')} ${className ? className : ''}`}
      style={{ minHeight: '1px' }} /* prevents section from be invisible for the intersection observer */
    >
      {/* The placeholder, renders inmediately */}
      {placeholderType && !isComponentReady && (
        <Placeholder width='100%' height='100%' placeholderType={placeholderType} />
      )}
      {/* The module, will only render when scroll gets closer */}
      {appearedFirstTime && <div {...wrapperProps}>{React.cloneElement(children, rest)}</div>}
    </section>
  )
}

export default LazyComponent
