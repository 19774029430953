import { FC } from 'react'
import styled, { keyframes } from 'styled-components'
import { Container as ContainerUIKIT, Row, Col } from '@mch-group/uikit-components'
import tokens from '@mch-group/design-tokens/build/js/design_tokens-module'

const Placeholder: FC<IPlaceholder> = ({ width = '588', height = '288', placeholderType = 1, className }) => {
  const type = placeholderType // "type" is the old name
  if (type === 2) {
    return (
      <Container className={className}>
        <svg width={width} height={height} viewBox='0 0 343 102' version='1.1' xmlns='http://www.w3.org/2000/svg'>
          <title>placeholder_mobile</title>
          <g id='Page-1' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
            <g id='placeholder_mobile' fill={tokens.color.light.base.neutrals['500'].value} fillRule='nonzero'>
              <rect id='title1' x='0' y='0' width='343' height='28' />
              <rect id='title2' x='0' y='44' width='343' height='28' />
              <polygon id='title3' points='0 88 183 88 183 102 0 102' />
            </g>
          </g>
        </svg>
      </Container>
    )
  }

  if (type === 3) { // desktop hybrid room
    return (
      <Container className={className}>
        <svg width={width} height={height} viewBox='0 0 778 106' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <path d='M778 33H0V61H778V33Z' fill={tokens.color.light.base.neutrals['500'].value} />
          <path d='M507 77H0V105H507V77Z' fill={tokens.color.light.base.neutrals['500'].value} />
          <path d='M14 0H0V14H14V0Z' fill={tokens.color.light.base.neutrals['500'].value} />
          <path d='M127 0H25V14H127V0Z' fill={tokens.color.light.base.neutrals['500'].value} />
        </svg>
      </Container>
    )
  }

  if (type === 4) { // mobile hybrid room
    return (
      <Container className={className}>
        <svg width={width} height={height} viewBox='0 0 343 95' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <path d='M343 29H0V57H343V29Z' fill={tokens.color.light.base.neutrals['500'].value} />
          <path d='M287 67H0V95H287V67Z' fill={tokens.color.light.base.neutrals['500'].value} />
          <path d='M13 0H0V13H13V0Z' fill={tokens.color.light.base.neutrals['500'].value} />
          <path d='M81 0H18V13H81V0Z' fill={tokens.color.light.base.neutrals['500'].value} />
        </svg>
      </Container>
    )
  }

  if (type === 5) { // Big rectangle, useful for one whole element, full width no container.
    return (
      <p className='placeholder-glow mb-15' aria-hidden='true'>
        <span className='placeholder col-12' style={{ height: '600px' }} />
      </p>
    )
  }

  if (type === 6) { // Big rectangle, useful for one whole element, full width no container.
    return (
      <ContainerUIKIT className={`${className ? className : ''} px-5 px-lg-8 px-md-7 mb-16`}>
        <Row>
          <Col>
            <p className='placeholder-glow' aria-hidden='true'>
              <span className='placeholder mb-6 col-12' style={{ height: '200px' }} />
              <span className='placeholder mb-5 col-12' style={{ height: '10px' }} />
              <span className='placeholder mb-5 col-12' style={{ height: '10px' }} />
              <span className='placeholder mb-5 col-12' style={{ height: '10px' }} />
            </p>
          </Col>
          <Col>
            <p className='placeholder-glow' aria-hidden='true'>
              <span className='placeholder mb-6 col-12' style={{ height: '200px' }} />
              <span className='placeholder mb-5 col-12' style={{ height: '10px' }} />
              <span className='placeholder mb-5 col-12' style={{ height: '10px' }} />
              <span className='placeholder mb-5 col-12' style={{ height: '10px' }} />
            </p>
          </Col>
          <Col>
            <p className='placeholder-glow' aria-hidden='true'>
              <span className='placeholder mb-6 col-12' style={{ height: '200px' }} />
              <span className='placeholder mb-5 col-12' style={{ height: '10px' }} />
              <span className='placeholder mb-5 col-12' style={{ height: '10px' }} />
              <span className='placeholder mb-5 col-12' style={{ height: '10px' }} />
            </p>
          </Col>
        </Row>
      </ContainerUIKIT>
    )
  }

  if (type === 7) { // Big rectangle, useful for images
    return (
      <ContainerUIKIT className={`${className ? className : ''} px-5 px-lg-8 px-md-7`}>
        <Row>
          <Col>
            <p className='placeholder-glow' aria-hidden='true'>
              <span className='placeholder mb-6 col-12' style={{ height: '700px' }} />
            </p>
          </Col>
        </Row>
      </ContainerUIKIT>
    )
  }

  if (type === 8) { // full width hero + 3 columns
    return (
      <>
        <p className='placeholder-glow mb-10' aria-hidden='true'>
          <span className='placeholder col-12' style={{ height: '600px' }} />
        </p>
        <ContainerUIKIT className={`${className ? className : ''} px-5 px-lg-8 px-md-7 mb-15`}>
          <Row>
            <Col>
              <p className='placeholder-glow' aria-hidden='true'>
                <span className='placeholder mb-6 col-12' style={{ height: '200px' }} />
                <span className='placeholder mb-5 col-12' style={{ height: '10px' }} />
                <span className='placeholder mb-5 col-12' style={{ height: '10px' }} />
                <span className='placeholder mb-5 col-12' style={{ height: '10px' }} />
              </p>
            </Col>
            <Col>
              <p className='placeholder-glow' aria-hidden='true'>
                <span className='placeholder mb-6 col-12' style={{ height: '200px' }} />
                <span className='placeholder mb-5 col-12' style={{ height: '10px' }} />
                <span className='placeholder mb-5 col-12' style={{ height: '10px' }} />
                <span className='placeholder mb-5 col-12' style={{ height: '10px' }} />
              </p>
            </Col>
            <Col>
              <p className='placeholder-glow' aria-hidden='true'>
                <span className='placeholder mb-6 col-12' style={{ height: '200px' }} />
                <span className='placeholder mb-5 col-12' style={{ height: '10px' }} />
                <span className='placeholder mb-5 col-12' style={{ height: '10px' }} />
                <span className='placeholder mb-5 col-12' style={{ height: '10px' }} />
              </p>
            </Col>
          </Row>
        </ContainerUIKIT>
      </>
    )
  }
  if (type === 9) { // full width hero + 3 columns
    return (
      <>
        <ContainerUIKIT className={`${className ? className : ''} px-5 px-lg-8 px-md-7 mb-15`}>
          <Row>
            <Col>
              <p className='placeholder-glow' aria-hidden='true'>
                <span className='placeholder mb-6 col-12' style={{ height: '700px' }} />
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <p className='placeholder-glow' aria-hidden='true'>
                <span className='placeholder mb-6 col-12' style={{ height: '200px' }} />
                <span className='placeholder mb-5 col-12' style={{ height: '10px' }} />
                <span className='placeholder mb-5 col-12' style={{ height: '10px' }} />
                <span className='placeholder mb-5 col-12' style={{ height: '10px' }} />
              </p>
            </Col>
            <Col>
              <p className='placeholder-glow' aria-hidden='true'>
                <span className='placeholder mb-6 col-12' style={{ height: '200px' }} />
                <span className='placeholder mb-5 col-12' style={{ height: '10px' }} />
                <span className='placeholder mb-5 col-12' style={{ height: '10px' }} />
                <span className='placeholder mb-5 col-12' style={{ height: '10px' }} />
              </p>
            </Col>
            <Col>
              <p className='placeholder-glow' aria-hidden='true'>
                <span className='placeholder mb-6 col-12' style={{ height: '200px' }} />
                <span className='placeholder mb-5 col-12' style={{ height: '10px' }} />
                <span className='placeholder mb-5 col-12' style={{ height: '10px' }} />
                <span className='placeholder mb-5 col-12' style={{ height: '10px' }} />
              </p>
            </Col>
          </Row>
        </ContainerUIKIT>
      </>
    )
  }

  if (type === 'AOVR') { // AOVR
    return (
      <>
        <ContainerUIKIT className={`${className ? className : ''} px-5 px-lg-8 px-md-7 mb-15`}>
          <Row>
            <Col>
              <p className='placeholder-glow' aria-hidden='true'>
                <span className='placeholder mb-0 col-12 d-lg-none' style={{ height: '80px' }} />
                <span className='placeholder mt-0 col-12 d-lg-none' style={{ height: '10px' }} />
                <span className='placeholder col-lg-6 mb-6 d-none d-lg-inline-block' style={{ height: '30px' }} />
                <span className='placeholder col-lg-5 offset-lg-1 lg-6 d-none d-lg-inline-block' style={{ height: '380px' }} />
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <p className='placeholder-glow' aria-hidden='true'>
                <span className='placeholder mb-0 col-12' style={{ height: '80px' }} />
              </p>
            </Col>
          </Row>
        </ContainerUIKIT>
      </>
    )
  }

  return (
    <Container className={className}>
      <svg width={width} height={height} viewBox='0 0 588 288' version='1.1' xmlns='http://www.w3.org/2000/svg'>
        <g id='Page-1' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
          <g id='Placeholder' fill={tokens.color.light.base.neutrals['500'].value} fillRule='nonzero'>
            <rect id='title1' x='0' y='0' width='588' height='28' />
            <rect id='title2' x='0' y='41' width='588' height='28' />
            <rect id='title3' x='0' y='82' width='183' height='28' />
            <rect id='line1' x='0' y='139' width='588' height='14' />
            <rect id='line2' x='0' y='166' width='588' height='14' />
            <rect id='line3' x='0' y='193' width='588' height='14' />
            <rect id='line4' x='0' y='220' width='588' height='14' />
            <rect id='line5' x='0' y='247' width='588' height='14' />
            <rect id='cta' x='0' y='274' width='183' height='14' />
          </g>
        </g>
      </svg>
    </Container>
  )
}

const opacityTilted = keyframes`
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.3;
  }

  100% {
    opacity: 1;
  }
`

const Container = styled.article`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  animation: ${opacityTilted} 3s ease-in-out infinite;
`

interface IPlaceholderType {
  placeholderType?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 'AOVR'
}

interface IPlaceholder extends IPlaceholderType {
  width?: string,
  height?: string,
  className?: string
}

export default Placeholder
export type { IPlaceholderType }
