import { useState, useEffect, ReactNode, useRef } from 'react'
import useOnScreen from '@hooks/useOnScreen'

/**
 * Loads a component only when it appears in screen
 */
const useLazyComponent = (ref: ReactNode): boolean => {
  const [appearedFirstTime, setAppearedFirstTime] = useState(false)
  const refVisible = useRef(false)
  const isVisible = useOnScreen(ref, '2000px')

  useEffect(() => {
    refVisible.current = isVisible
  }, [isVisible])


  useEffect(() => {
    if (!appearedFirstTime && isVisible) {
      setAppearedFirstTime(true)
    }
  }, [appearedFirstTime, isVisible])

  return appearedFirstTime
}

export default useLazyComponent